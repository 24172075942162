import * as THREE from 'three';
import { Mesh, Vector3, MathUtils } from 'three';
import gsap from 'gsap';
import theme from 'utils/theme';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json';
// import typefaceFont from 'three/examples/fonts/optimer_bold.typeface.json';
import typefaceFont from './fonts/ubuntu_mono.json';

interface IOptions {
  mountPoint: HTMLDivElement;
  width: number;
  height: number;
}

let startTime = Date.now();
let text;

class ThreeCanvas {
  private renderer: THREE.WebGLRenderer;
  private composer: THREE.Composer;
  private camera: THREE.Camera;
  private cubeGroup: THREE.Group;
  private clock: THREE.Clock;
  private controls: THREE.OrbitControls;

  constructor(options: IOptions) {
    const { mountPoint, width, height } = options;

    // this is just here for reference. most of this file should be overwritten :)

    // basics
    const clock = this.clock = new THREE.Clock();
    const scene = new THREE.Scene();
    const camera = this.camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );
    const renderer = this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
    });

    const controls = this.controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;

    scene.background = new THREE.Color( theme.colors.red );
    renderer.setSize( width, height );
    camera.position.z = 9;

    // post processing support
    const composer = this.composer = new EffectComposer( renderer );

    const renderPass = new RenderPass( scene, camera );
    renderPass.clear = false;
    composer.addPass( renderPass );

    // mount to DOM
    mountPoint.appendChild( renderer.domElement );
    // VR support
    // renderer.xr.enabled = true;
    // mountPoint.appendChild( VRButton.createButton( renderer ) );

    this.addMeshes(scene);

    const fontLoader = new THREE.FontLoader();
    const font = fontLoader.parse(typefaceFont);
    const textGeometry = new THREE.TextGeometry(
      'ORDINAL.GARDEN',
      {
        font,
        size: 0.8,
        height: 12.2,
        curveSegments: 5,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 5,
      },
    );

    const textureLoader = new THREE.TextureLoader();
    const matcapTexture = textureLoader.load('/textures/matcaps/8.png');
    // const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
    const material = new THREE.MeshNormalMaterial();
    // const material = new THREE.MeshStandardMaterial();
    // material.color = new THREE.Color(theme.colors.blue);
    // material.roughness = 0.4;
    text = new THREE.Mesh(textGeometry, material);
    text.rotation.x = Math.PI * -0.08;
    text.position.y = -1.2;
    scene.add(text);
    textGeometry.computeBoundingBox();
    console.log(textGeometry.boundingBox);

    textGeometry.center();
    // const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
    // scene.add(ambientLight)

    // const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);

    // for(let i = 0; i < 100; i++) {
      // const donut = new THREE.Mesh(donutGeometry, material);
      // const scale = Math.random();
      // donut.scale.set(scale, scale, scale);
      // donut.position.x = (Math.random() - 0.5) * 10;
      // donut.position.y = (Math.random() - 0.5) * 10;
      // donut.position.z = (Math.random() - 0.5) * 10;
      // donut.rotation.x = Math.random() * Math.PI;
      // donut.rotation.y = Math.random() * Math.PI;
      // // scene.add(donut);
    // }
  }

  addMeshes(scene: THREE.Scene) {
    const cubeGroup = this.cubeGroup = new THREE.Group();
    const cubeInitialPositions = [
      {
        rotation: new Vector3(35, 35, 0),
        position: new Vector3(0, -0.5, 0),
      },
      {
        rotation: new Vector3(-35, -95, 0),
        position: new Vector3(0, 1, 0),
      },
    ];

    const material = new THREE.MeshBasicMaterial( { color: theme.baseFontColor } );
    for (let i=0; i < 2; i++) {
      const geometry= new THREE.BoxGeometry();

      const cube = new Mesh( geometry, material );
      cubeGroup.add(cube);

      cube.rotation.set(cubeInitialPositions[i].rotation.x, cubeInitialPositions[i].rotation.y, cubeInitialPositions[i].rotation.z,);
      cube.position.set(cubeInitialPositions[i].position.x, cubeInitialPositions[i].position.y, cubeInitialPositions[i].position.z,);
    }

    cubeGroup.position.z = -9; // push 9 meters back
    // scene.add(cubeGroup);
  }

  resizeRendererToDisplaySize(renderer: THREE.WebGLRenderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    const needResize = canvas.width !== width || canvas.height !== height;

    if (needResize) {
      renderer.setSize(width, height, false);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)); // use 2x pixel ratio at max
    }

    return needResize;
  }

  public setAnimationLoop(callback: Function) {
    this.renderer.setAnimationLoop(callback);
  }

  render() {
    // check if we need to resize the canvas and re-setup the camera
    if (this.resizeRendererToDisplaySize(this.renderer)) {
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();
    }

    // this.cubeGroup.rotation.y = this.clock.getElapsedTime();
    text.rotation.y = Math.sin(this.clock.getElapsedTime() * 1.5)/6;

    this.controls.update();
    this.composer.render();
  }
}

export default ThreeCanvas;
